/* eslint-disable camelcase */
import React from "react";
import moment from "moment";
import Button from "app/components/Button";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import { H3, P1, A } from "app/components/Typography";
import { ClassList } from "app/components/ClassList";
import { DATE_FORMAT_FOR_SCHEDULES, IS_MOBILE } from "constants/index";
import styled from "styled-components";
import { ScheduleClassRow } from "./ScheduleClassRow";
import { EditClasses } from "./EditClasses";
import { formatDateToString } from "../formatDateToString";
import { TakenClasses } from "./TakenClasses";

const CompletedGradient = styled(Div).attrs({
  position: "absolute",
  height: "60%",
  width: "100%",
  bottom: 0,
  left: 0,
})`
  ${({ theme }) => `
      background: linear-gradient(0deg, ${theme.colors.bulbaGreen} -212.3%, rgba(129, 241, 174, 0) 100%);
    `}
`;

// @TODO: Add prop types
export const TodaysClasses = ({
  selectedPlaylist,
  isEditingMode,
  isScheduleEditable,
  toggleEditPlaylistMode,
  removeTakenClassMutation,
  selectedDate,
  onUserSelectsClass,
}: any) => {
  const dateString = formatDateToString(selectedDate);

  const onRemoveClass = (classData: any) => {
    removeTakenClassMutation({
      variables: {
        classIdentifier: {
          id: classData.id,
          refId: classData.refId,
        },
        date: moment(selectedDate).format(DATE_FORMAT_FOR_SCHEDULES),
      },
    });
  };

  return (
    <>
      {selectedPlaylist?.progress?.completed && !isEditingMode && (
        <CompletedGradient />
      )}
      <Div position="relative" width="100%" height="100%" overflow="auto">
        <Flex flexDirection="column" width="100%" height="100%">
          <Flex flex={1} height="100%" flexDirection="column">
            <Div>
              {!isEditingMode && (
                <TakenClasses
                  selectedPlaylist={selectedPlaylist}
                  dateString={dateString}
                  onRemoveClass={onRemoveClass}
                />
              )}
              <TodaysScheduleHeader
                selectedDate={selectedDate}
                isEditingMode={isEditingMode}
                isScheduleEditable={isScheduleEditable}
                selectedPlaylist={selectedPlaylist}
                toggleEditPlaylistMode={toggleEditPlaylistMode}
              />
            </Div>
            {(() => {
              if (isEditingMode && isScheduleEditable) {
                return (
                  <Div height="100%" width="100%">
                    <EditClasses
                      selectedPlaylist={selectedPlaylist}
                      selectedDate={selectedDate}
                    />
                  </Div>
                );
              }

              if (IS_MOBILE) {
                return (
                  <Flex>
                    <ClassList
                      gridTemplateColumns="1fr"
                      classes={selectedPlaylist?.classes}
                      selectedFrom="ForYou"
                    />
                  </Flex>
                );
              }

              if (!selectedPlaylist?.classes) {
                return (
                  <Flex
                    height="100%"
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                    p={3}
                    bg="monochrome.7"
                    borderRadius="4px"
                  >
                    <Div color="white" textAlign="center">
                      <H3>This schedule is empty.</H3>
                      <P1 mt={2} maxWidth="350px">
                        Start adding classes now!
                      </P1>
                    </Div>
                  </Flex>
                );
              }

              return (
                <Div position="relative" flex={1} width="100%" height="100%">
                  <Div
                    position={{ _: "relative", lg: "absolute" }}
                    height="100%"
                    width="100%"
                  >
                    <Div position="relative" height="100%">
                      {selectedPlaylist?.classes &&
                        selectedPlaylist?.classes.map((classData: any) => (
                          <ScheduleClassRow
                            classData={classData}
                            selectedPlaylist={selectedPlaylist}
                            onUserSelectsClass={onUserSelectsClass}
                          />
                        ))}
                    </Div>
                  </Div>
                </Div>
              );
            })()}
          </Flex>
          {isEditingMode && (
            <Flex
              px={IS_MOBILE ? 0 : 4}
              py={IS_MOBILE ? 0 : 4}
              flexDirection="column"
            >
              <Button
                onClick={() => toggleEditPlaylistMode(false)}
                m={IS_MOBILE ? 4 : 0}
              >
                Back to my schedule
              </Button>
            </Flex>
          )}
        </Flex>
      </Div>
    </>
  );
};

const TodaysScheduleHeader = ({
  selectedDate,
  isEditingMode,
  isScheduleEditable,
  selectedPlaylist,
  toggleEditPlaylistMode,
}: any) => {
  const dateString = formatDateToString(selectedDate);

  if (selectedPlaylist?.progress?.completed && !isEditingMode) {
    return (
      <>
        <H3 color="white">{dateString}&apos;s Classes</H3>
        <Div color="white" mt={2} mb={3}>
          <Flex justifyContent="space-between">
            <P1 color="bulbaGreen">Completed!</P1>
            {isScheduleEditable && (
              <A color="white" onClick={() => toggleEditPlaylistMode(true)}>
                Edit
              </A>
            )}
          </Flex>
        </Div>
      </>
    );
  }

  if (isEditingMode && isScheduleEditable) {
    return (
      <>
        <H3 color="white" pt={{ _: 0, lg: 2 }}>
          {dateString}&apos;s Classes
        </H3>
        <Div color="white" mt={2} mb={3}>
          <P1>
            Use the calendar to plan or view your schedule. Search and add
            classes into your schedule!
          </P1>
        </Div>
      </>
    );
  }

  return (
    <Div color="white" mb={3}>
      <Flex justifyContent="space-between">
        <H3 color="white">{dateString}&apos;s Classes</H3>
        {isScheduleEditable && (
          <A color="white" onClick={() => toggleEditPlaylistMode(true)}>
            Edit
          </A>
        )}
      </Flex>
    </Div>
  );
};
