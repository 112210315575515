import React from "react";
import { H3 } from "app/components/Typography";
import Div from "app/components/Div";
import Img from "app/components/Img";
import env from "helpers/env";
import { TakenClassRow } from "./TakenClassRow";

interface Props {
  selectedPlaylist: any;
  dateString: string;
  onRemoveClass(classData: any): void;
}

export function TakenClasses({
  selectedPlaylist,
  dateString,
  onRemoveClass,
}: Props) {
  return (
    <>
      <H3 color="white">Classes Taken {dateString}</H3>
      <Div my={3} pb={2} borderBottom="1px solid" borderColor="monochrome.6">
        {selectedPlaylist?.takenClasses?.length ? (
          selectedPlaylist?.takenClasses.map((classData: any) =>
            classData ? (
              <TakenClassRow
                key={classData.id}
                classData={classData}
                onRemove={() => onRemoveClass(classData)}
              />
            ) : null
          )
        ) : (
          <Img
            src={`${env("PUBLIC_ASSET_PATH")}/icons/empty-task-cta.svg`}
            width="100%"
          />
        )}
      </Div>
    </>
  );
}
