import React from "react";
import Flex from "app/components/Flex";
import Text from "app/components/Text";
import { H1, H3 } from "app/components/Typography";
import PropTypes from "prop-types";
import Icon, { Check } from "app/components/Icon";
import { setSeenPlaylistCompleteModalAction } from "modules/progress";
import _ from "lodash";
import { connect } from "react-redux";
import {
  ModalHeader,
  ModalWrapper,
  ModalBody,
} from "app/components/Modal/components";

const CompletedPlaylistModal = ({
  playlist,
  setSeenPlaylistCompleteModal,
  toggleModal,
}) => {
  React.useEffect(() => {
    if (!_.get(playlist, "progress.seen_completed_modal")) {
      setSeenPlaylistCompleteModal({ playlistId: playlist.id });
    }
  }, [playlist, setSeenPlaylistCompleteModal]);
  return (
    <ModalWrapper>
      <ModalHeader p={3} toggleModal={toggleModal}>
        <H1 color="bulbaGreen">
          Schedule <br />
          Complete!
        </H1>
      </ModalHeader>
      <ModalBody p={3}>
        <Flex flexDirection="column">
          <H3 mb={2}>Classes you took today</H3>
          {playlist.classes &&
            playlist.classes.map(classData => (
              <Flex alignItems="center" my={2}>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  width="25px"
                  height="25px"
                  bg="bulbaGreen"
                  borderRadius="5px"
                >
                  <Icon color="white" width="20px" as={Check} p="2px" />
                </Flex>
                <Text
                  ml={3}
                  fontSize="12px"
                  fontWeight="bold"
                  textTransform="uppercase"
                  letterSpacing="0.08em"
                >
                  {classData.title}
                </Text>
              </Flex>
            ))}
        </Flex>
      </ModalBody>
    </ModalWrapper>
  );
};

const mapDispatchToProps = {
  setSeenPlaylistCompleteModal: setSeenPlaylistCompleteModalAction,
};

CompletedPlaylistModal.propTypes = {
  playlist: PropTypes.shape({}).isRequired,
  setSeenPlaylistCompleteModal: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(CompletedPlaylistModal);
