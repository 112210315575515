import { gql, useMutation } from "@apollo/client";
import { TOGGLE_SAVE_CLASS_MUTATION } from "graphql/mutations";
import { contentToggleSaveClass } from "services/typewriter/segment";
import { useToggleSaveScheduleClassMutation } from "services/graphql";

export function useSaveScheduleClass() {
  const [toggleSaveProgramClassMutation] = useToggleSaveScheduleClassMutation();

  return async ({ variables, component, classData }) => {
    const { classId, willSave } = variables;
    const mutationResults = await toggleSaveProgramClassMutation({
      variables: {
        classId: String(classId),
        willSave,
      },
      optimisticResponse: {
        toggleSaveClass: {
          id: String(classId),
          isSaved: willSave,
          __typename: "Class",
        },
      },
      update: (cache, { data }) => {
        cache.writeFragment({
          id: `PlaylistClass:${data?.toggleSaveClass?.id}`,
          fragment: gql`
            fragment scheduleClass on PlaylistClass {
              isSaved
            }
          `,
          data: {
            isSaved: data?.toggleSaveClass?.isSaved,
          },
        });
      },
    });

    contentToggleSaveClass({
      saved: willSave,
      component,
      classId,
      instructor: classData.instructor_name || classData.instructor?.name,
      level: classData.level,
      style: classData.style,
      title: classData.title,
      categories: classData.categories,
    });

    return mutationResults;
  };
}
