import React from "react";
import moment from "moment";
import Calendar from "app/components/Calendar";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import Grid from "app/components/Grid";
import Text from "app/components/Text";
import { H3, P1 } from "app/components/Typography";
import CompletedPlaylistModal from "./CompletedPlaylistModal";
import { TodaysClasses } from "./TodaysClasses";

// @TODO: Add prop types
export const ScheduleEditor = ({
  isEditingMode,
  setDate,
  selectedDate,
  toggleWeeklyStreakModal,
  calendarData,
  isScheduleEditable,
  showCompletedPlaylistModal,
  toggleCompletedPlaylistModal,
  selectedPlaylist,
  toggleEditPlaylistMode,
  removeTakenClassMutation,
  isAfterCurrentDate,
  onUserSelectsClass,
  wrapperRef,
}: any) => {
  if (showCompletedPlaylistModal) {
    return (
      <Flex
        height="100%"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Div m={3} maxWidth="400px">
          <CompletedPlaylistModal
            playlist={selectedPlaylist}
            toggleModal={() => toggleCompletedPlaylistModal(false)}
          />
        </Div>
      </Flex>
    );
  }

  return (
    <Grid
      gridTemplateColumns={{
        _: "1fr",
        md: "1fr 1fr",
        lg: "40% 1fr",
      }}
      gridGap={4}
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      p={{ _: 3, lg: 4 }}
    >
      <Flex
        flexDirection="column"
        borderRight={{ lg: "2px solid" }}
        borderColor="white"
        height="100%"
      >
        <Div width="100%">
          <Flex>
            <Calendar
              onSelectDate={setDate}
              currentDate={moment(selectedDate).toDate()}
              highlightedDays={calendarData?.dailyStreaks?.map(
                (dailyStreak: any) =>
                  parseInt(moment(dailyStreak?.date).format("D"))
              )}
              indicatedDays={calendarData?.userCreatedPlaylists?.reduce(
                (acc: any, playlist: any) => {
                  if (
                    !moment().isBefore(playlist.date) ||
                    playlist.classes?.length < 1
                  ) {
                    return acc;
                  }

                  return [...acc, parseInt(moment(playlist.date).format("D"))];
                },
                []
              )}
            />
          </Flex>
          <Grid
            gridTemplateColumns="repeat(2, 1fr)"
            borderTop="1px solid"
            borderColor="monochrome.6"
            width="100%"
          >
            <Text
              cursor="pointer"
              color="white"
              fontSize="11px"
              letterSpacing="0.04em"
              textTransform="uppercase"
              lineHeight="142%"
              onClick={() => toggleWeeklyStreakModal(true)}
              borderRight="1px solid"
              borderColor="monochrome.6"
              pr={2}
            >
              <Text fontWeight="bold" mt={2}>
                {calendarData?.weeklyStreakGoal === 1
                  ? `${calendarData?.weeklyStreakGoal} Day`
                  : `${calendarData?.weeklyStreakGoal} Days`}{" "}
                Per Week
              </Text>
              <Text mb={2}>Weekly Dance Goal</Text>
            </Text>
            <Text
              color="white"
              fontSize="11px"
              letterSpacing="0.04em"
              textTransform="uppercase"
              lineHeight="142%"
              textAlign="right"
            >
              <Text fontWeight="bold" mt={2}>
                Weekly Streak: {calendarData?.weeklyStreakCount || 0}x
              </Text>
              <Text mb={2}>Dance Goal Achieved</Text>
            </Text>
          </Grid>
        </Div>
      </Flex>
      <Flex
        flex="1"
        height="100%"
        minHeight={{ _: "380px", md: "unset" }}
        overflow="hidden"
        justifyContent="center"
      >
        <Div maxWidth="900px" width="100%">
          <TodaysClasses
            selectedPlaylist={selectedPlaylist}
            isEditingMode={isEditingMode}
            isScheduleEditable={isScheduleEditable}
            toggleEditPlaylistMode={toggleEditPlaylistMode}
            removeTakenClassMutation={removeTakenClassMutation}
            selectedDate={selectedDate}
            isAfterCurrentDate={isAfterCurrentDate}
            wrapperRef={wrapperRef}
            onUserSelectsClass={onUserSelectsClass}
          />
        </Div>
      </Flex>
    </Grid>
  );
};
