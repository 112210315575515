import React from "react";
import ClassRow from "app/components/ClassRow";
import Flex from "app/components/Flex";

import { Class } from "services/graphql";
import HeartIconButton from "app/components/Button/IconButton/HeartIconButton";
import { useSaveScheduleClass } from "./hooks";
import { DropdownMenuOptions } from "./DropdownMenuOptions";

interface Props {
  classData: Class;
  onUserSelectsClass(classData: Partial<Class>): any;
}

export function ScheduleClassRow({ classData, onUserSelectsClass }: Props) {
  const toggleSaveClassMutation = useSaveScheduleClass();

  if (!classData) {
    return null;
  }

  return (
    <Flex alignItems="center" mb="10px" width="100%" key={classData.id}>
      <Flex
        width="100%"
        borderWidth="3px"
        borderStyle="solid"
        borderRadius="2px"
        borderColor={
          classData?.progress?.completed ? "bulbaGreen" : "transparent"
        }
        cursor="pointer"
      >
        <ClassRow
          bg="monochrome.6"
          classData={classData}
          textColor="white"
          onClick={() => onUserSelectsClass(classData)}
          ChildrenComponent={() => {
            return (
              <Flex alignItems="center" mx="12px">
                <HeartIconButton
                  isActive={classData.isSaved}
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    e.preventDefault();
                    toggleSaveClassMutation({
                      component: "ScheduleClassRow",
                      variables: {
                        classId: classData.id,
                        willSave: !classData.isSaved,
                      },
                      classData,
                    });
                  }}
                  color="monochrome.0"
                />
              </Flex>
            );
          }}
        />
      </Flex>
      <Flex alignItems="center" mx="8px" height="100%">
        <DropdownMenuOptions
          classData={classData}
          onUserSelectsClass={onUserSelectsClass}
        />
      </Flex>
    </Flex>
  );
}
