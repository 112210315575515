import React, { useState } from "react";
import Div from "app/components/Div";
import Lottie from "react-lottie-light";
import ClassRow from "app/components/ClassRow";
import Flex from "app/components/Flex";
import Icon, { CircleX } from "app/components/Icon";
import singleCardAnimationData from "./lottie-single-card.json";

export const TakenClassRow = ({ onRemove, ...props }) => {
  const [showClassRow, toggleClassRow] = useState(false);

  return (
    <>
      {!showClassRow && (
        <Lottie
          options={{
            autoplay: true,
            loop: false,
            animationData: singleCardAnimationData,
          }}
          eventListeners={[
            {
              eventName: "complete",
              callback: () => toggleClassRow(true),
            },
          ]}
        />
      )}
      <Div
        borderWidth="3px"
        borderStyle="solid"
        borderRadius="2px"
        borderColor="bulbaGreen"
        mb="10px"
        width="100%"
        opacity={showClassRow ? 1 : 0}
        transition="opacity 150ms ease-in"
        position={showClassRow ? "relative" : "absolute"}
        left={0}
      >
        <ClassRow
          bg="monochrome.6"
          ChildrenComponent={() => (
            <Flex justifyContent="center" alignItems="center" p={3}>
              <Icon
                color="monochrome.2"
                height="20px"
                as={CircleX}
                cursor="pointer"
                onClick={() => {
                  onRemove();
                }}
              />
            </Flex>
          )}
          {...props}
        />
      </Div>
    </>
  );
};
