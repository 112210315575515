import moment, { MomentInput } from "moment";

export function formatDateToString(date: string | MomentInput) {
  const isSelectedDateToday = moment().isSame(date, "day");
  const isSelectedDateTomorrow = moment()
    .add(1, "days")
    .isSame(date, "day");
  const isSelectedDateYesterday = moment()
    .subtract(1, "days")
    .isSame(date, "day");

  if (isSelectedDateToday) {
    return "Today";
  }

  if (isSelectedDateTomorrow) {
    return "Tomorrow";
  }

  if (isSelectedDateYesterday) {
    return "Yesterday";
  }

  return moment(date).format("MMM DD, YYYY");
}
