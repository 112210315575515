import React from "react";
import { DropdownLineSeparator } from "app/components/Dropdown";
import { Class } from "services/graphql";
import {
  AddClassToScheduleOption,
  AddClassToPlaylistOption,
  GoToClassStyleOption,
  GoToClassLevelOption,
  GoToInstructorOption,
  DropdownMenuWrapper,
  PreviewClassOption,
  MobilePreviewClassOption,
  ShareClassOption,
} from "app/components/ClassComponents/DropdownMenuOptions";
import Tooltip from "app/components/Tooltip";
import Icon, { KebabMenu } from "app/components/Icon";
import styled from "styled-components";
import { IS_MOBILE } from "constants/index";

interface Props {
  classData: Partial<Class>;
  onUserSelectsClass(classData: Partial<Class>): any;
}

export const DropdownMenuOptions = ({
  classData,
  onUserSelectsClass,
}: Props) => {
  const classNumber = Number(classData.id);
  const classId = classData.studioClassId;

  return (
    <DropdownMenuWrapper menuButton={MenuButton} portal={false}>
      {IS_MOBILE ? (
        <MobilePreviewClassOption classData={classData} />
      ) : (
        <PreviewClassOption
          classData={classData}
          onUserSelectsClass={onUserSelectsClass}
        />
      )}
      <AddClassToPlaylistOption classNumber={classNumber} classId={classId} />
      <AddClassToScheduleOption classNumber={classNumber} />
      <ShareClassOption classData={classData} />
      <DropdownLineSeparator />
      <GoToClassStyleOption styleName={classData.style} />
      <GoToClassLevelOption classLevel={classData.level} />
      <GoToInstructorOption instructorSlug={classData.instructor?.slug} />
    </DropdownMenuWrapper>
  );
};

const Button = styled.button`
  padding: 0;
  border: 0;
  background: none;
`;

function MenuButton() {
  return (
    <Button>
      <Tooltip overlay="More options">
        <Icon as={KebabMenu} width="20px" height="20px" color="white" />
      </Tooltip>
    </Button>
  );
}
